import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';





@Component({
  selector: 'app-vendor-header',
  templateUrl: './vendor-header.component.html',
  styleUrls: ['./vendor-header.component.scss'],
})
export class VendorHeaderComponent implements OnInit {



  @Input() title: string;
  userData1: any = [];


  constructor(private route: Router, private commonService: CommonService) {
    this.userData1 = JSON.parse(localStorage.getItem('userData1')!);
    console.log(this.userData1)

  }

  ngOnInit() {

    this.commonService.orderStatusChange.subscribe((res) => {

      console.log(res);


    });

  }

  myAccout(){
    this.route.navigate(['home/admin/vendor-profile/'+this.userData1.vendor_id]);
  }

  goToHome() {
    this.route.navigate(['home/admin/admin-landing']);

  }



  logout() {
    localStorage.clear();

    this.route.navigate(['home/admin/admin-login']).then(() => {
      window.location.reload();
    });;

  }

}
