import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.scss'],
})
export class CustomerHeaderComponent implements OnInit {
  @Input() title: string;
  userData: any =[];

  cartTotal = { total_qty: 0, total_price: 0 };
  // dropdown = false;
  // dropdown1 = false;

  // @ViewChild('productbtn', { read: ElementRef })productbtn: ElementRef;
  // @ViewChild('industriesbtn', { read: ElementRef })industriesbtn: ElementRef;

  constructor(private route: Router, private commonService: CommonService) {
    this.userData = this.commonService.getCurrentCustomerDetails();
  }

  ngOnInit() {
    this.commonService.cartChange.subscribe((res) => {
      this.cartTotal = this.commonService.getTotal();


    });
  }

  // hideDropdown(event) {
  //   const xTouch = event.clientX;
  //   const yTouch = event.clientY;

  //   const rect = this.productbtn.nativeElement.getBoundingClientRect();
  //   const topBoundary = rect.top+1;
  //   const leftBoundary = rect.left+1;
  //   const rightBoundary = rect.right-1;

  //   if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
  //     this.dropdown = false;
  //   }

  // }

  // hideDropdownIndustries(event1) {
  //   const xTouch = event1.clientX;
  //   const yTouch = event1.clientY;

  //   const rect = this.industriesbtn.nativeElement.getBoundingClientRect();
  //   const topBoundary = rect.top+1;
  //   const leftBoundary = rect.left+1;
  //   const rightBoundary = rect.right-1;

  //   if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
  //     this.dropdown = false;
  //   }

  // }

  goToHome() {
    this.route.navigate(['home/office/landing']);
    localStorage.removeItem("myProductslist1");
  }

  goToCart() {
    this.route.navigate(['home/customer/cart']);
    localStorage.removeItem("myProductslist1");
  }

  logout() {
    localStorage.clear();

    this.route.navigate(['/home/office/login']).then(() => {
      window.location.reload();
    });
  }

}
