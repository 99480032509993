import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { VendorHeaderComponent } from './vendor-header/vendor-header.component';





@NgModule({
  declarations: [CustomerHeaderComponent, VendorHeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,

  ],
  exports: [CustomerHeaderComponent, VendorHeaderComponent, ]
})
export class SharedComponentsModule { }
