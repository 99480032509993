import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Title }  from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedComponentsModule } from '../../src/app/components/shared-components.module';
import { NgChartsModule } from 'ng2-charts';


import { CommonService } from './common.service';
import { CommonModule } from '@angular/common';

import 'chartjs-plugin-zoom';



@NgModule({
  declarations: [AppComponent],
  imports: [ BrowserModule, CommonModule, NgbModule,  IonicModule.forRoot(), SharedComponentsModule, AppRoutingModule, HttpClientModule, NgOtpInputModule, Ng2SearchPipeModule, NgChartsModule ],
  providers: [CommonService,  Title,  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy } ],
  bootstrap: [AppComponent],
})
export class AppModule {}
