import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { BehaviorSubject } from 'rxjs';





@Injectable({
  providedIn: 'root'
})
export class CommonService {

  url = 'https://1c8e-3-110-208-221.ngrok-free.app/';
  @Output() cartChange: EventEmitter<boolean> = new EventEmitter();
  @Output() orderStatusChange: EventEmitter<boolean> = new EventEmitter();
  httpResponse: any = [];
  authenticationState = new BehaviorSubject(false);
  addDiscountItems: any = [];

  cartItems: any = [];
  serviceCartTotal: any = [];



  constructor(
    private http: HttpClient,



  ) {
    this.getCurrentCustomerDetails();
    this.getCurrentVendorDetails();
    console.log(this.cartItems)

  }

  orderStatusChangeTricker(){
    this.orderStatusChange.emit();
  }

  setCustomerUpdate(obj): void {
    localStorage.setItem('userData', JSON.stringify(obj));
    this.authenticationState.next(true);
  }

  getCurrentCustomerDetails():any {
    return JSON.parse(localStorage.getItem('userData')!);

  }

  setVendorUpdate(obj): void {
    localStorage.setItem('userData1', JSON.stringify(obj));
    this.authenticationState.next(true);
  }

  getCurrentVendorDetails():any {
    return JSON.parse(localStorage.getItem('userData1')!);

  }

  /************* Dicount setup *********** */
  setCart(item: any) {

    let index1 = this.checkExistInCart(item);
    if (index1 > -1) {
      this.cartItems[index1].qty =
        Number(this.cartItems[index1].qty) + Number(item.qty);
      //console.log(this.cartItems[index1].qty)
      item.customer_discount = Number(item.customer_discount);
      console.log(item.customer_discount)
    } else {
      //push also can use below
      console.log(item)
      item.qty = Number(item.qty);
      item.customer_discount = Number(item.customer_discount);
      console.log(item.customer_discount)
      this.cartItems.unshift(item);
    }
  }

  getCart() {
    return this.cartItems;
  }

  checkExistInCart(item: any) {

    let index = -1;
    if (this.cartItems.length > 0) {
      for (let i = 0; i < this.cartItems.length; i++) {
        if (item.product_id == this.cartItems[i].product_id) {
          index = i;
          break;
        }
      }
    }
    return index;
  }

  getTotal() {
    if (this.cartItems.length > 0) {

      let qty = 0;
      let price = 0;
      let discount1 = 0;
      let netPrice = 0;
      for (let i = 0; i < this.cartItems.length; i++) {
        qty += Number(this.cartItems[i].qty);
        price += Number(
          this.cartItems[i].price * Number(this.cartItems[i].qty)
        );
        discount1 =Number(price*this.cartItems[i].customer_discount/100);

      }

      netPrice = Number (price - discount1);
      price = Math.round(price)
      this.serviceCartTotal = { total_qty: qty, total_price: price };
    }
    return this.serviceCartTotal;
  }

  removeCart(index: any) {
    this.cartItems.splice(index, 1);

  }

  clearCart() {
    this.cartItems = [];
  }

  cartChangeTricker() {


    this.cartChange.emit();
  }


  /***********************************************************************end***************** */

  cache(obj: { cache: any; method: any; output: any }): Promise<any> {
    if (this.httpResponse[obj.method] && obj.cache) {
      return Promise.resolve({
        status: true,
        output: this.httpResponse[obj.method],
      });
    }
    return new Promise((resolve) => {
      this.http
        .post(this.url + obj.method, obj.output)
        .subscribe(
          (res: any) => {
            this.httpResponse[obj.method] = res;
            return resolve({
              status: true,
              output: this.httpResponse[obj.method],
            });
          },
          (error) => {
            //exception handling
            this.httpResponse[obj.method] = error;
            return resolve({
              status: false,
              output: this.httpResponse[obj.method],
            });
          }
        );
    });
  }

  cacheReset(obj: { method: string | number }) {
    this.httpResponse[obj.method] = false;
  }
}
